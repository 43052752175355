import React, { useRef } from "react";
import Slider from "react-slick";
import Button from "../../common/buttons/Button";
import { generateCDN } from "../../../utils/common";

import AccommodationWrapper from "./AccommodationWrapper";

const AccommodationSlider = ({ onClose, list }) => {
  let slider = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };

  function prevSlide() {
    slider.slickPrev();
  }

  function nextSlide() {
    slider.slickNext();
  }
  return (
    <AccommodationWrapper onClose={onClose} prev={prevSlide} next={nextSlide}>
      <div
        className="fixed top-[60%] sm:top-1/2 slider-show right-1/2 translate-x-1/2 -translate-y-1/2 z-[999] box-border"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="relative inline-block max-w-[100vw] w-full text-center">
          <Slider {...settings} ref={(c) => (slider = c)}>
            {list.map((item, index) => (
              <div
                key={index}
                className="relative max-w-[1000px] w-[96%] mx-auto z-[999] text-left align-middle max-h-full box-border"
              >
                <div
                  className="w-full bg-white overflow-hidden my-12  box-border"
                  style={{
                    boxShadow: "0px 0px 18px rgb(0 0 0 / 50%)",
                  }}
                >
                  <div className="block h-full sm:max-h-[872px] p-3">
                    <div
                      className="relative block w-full bg-cover bg-no-repeat bg-center"
                      style={{
                        backgroundImage: `linear-gradient(to right,rgba(34, 34, 34, 0.00),rgba(34, 34, 34, 0.00)),url(${generateCDN(
                          item?.slideBg?.asset?.url
                        )})`,
                      }}
                    >
                      <div className="max-w-[1440px] mx-auto relative">
                        <div className="flex flex-col justify-center min-h-[25vh] flex-wrap text-white">
                          <h1 className="text-[20px] sm:text-3xl font-bold uppercase pt-5 text-center mb-3">
                            {item.title}
                          </h1>

                          <div className="w-4/5 mb-6 bg-white/75 p-4 text-assent mx-auto">
                            <p className="font-bold text-xs sm:text-base">
                              {item.content}
                            </p>
                          </div>
                          <div className="block pt-[25px] pb-[15px] text-center">
                            <Button
                              btnText={item?.ctaButton1?.title}
                              btnLink={item?.ctaButton1?.link}
                              btnType={item?.ctaButton1?.variant}
                              linkType={item?.ctaButton1?.type}
                              formId={item?.ctaButton1?.formId}
                              downloadLink={item?.ctaButton1?.downloadLink}
                              small
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </AccommodationWrapper>
  );
};

export default AccommodationSlider;
