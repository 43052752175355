import React from "react";
import PortableText from "react-portable-text";
import { GatsbyImage } from "gatsby-plugin-image";
import Button from "../common/buttons/Button";

export default function Panama({ data }) {
  return (
    <div className="relative show">
      <div className="flex max-w-[1366px] mx-auto px-5 mt-6 md:mt-[145px]">
        <div className="w-full absolute left-0 top-10">
          <div className="relative z-50 flex w-full justify-start">
            <GatsbyImage
              image={data?.image?.asset?.gatsbyImageData}
              alt={data?.image?.alt}
              className="object-cover self-end"
            />
          </div>
        </div>
        <div className="w-full flex justify-end pt-[250px]  sm:pt-[450px] md:pt-[500px] lg:pt-[130px]  pb-[50px] md:pb-[190px] z-[150]">
          <div className="w-full px-2 xl:px-0 xl:max-w-[650px]">
            <PortableText
              content={data._rawContent}
              className={`portable-editor text-black block-content max-w-none text-base font-semibold`}
              serializers={{
                h2: ({ children }) => (
                  <h2 className="text-[32px] font-primary font-normal leading-[46px]">
                    {children}
                  </h2>
                ),
                h3: ({ children }) => (
                  <h3 className="text-2xl font-secondary text-black leading-[30px] mb-[47px]">
                    {children}
                  </h3>
                ),
                h4: ({ children }) => (
                  <h3 className="text-2xl font-secondary leading-[26px]">
                    {children}
                  </h3>
                ),
                ul: ({ children }) => (
                  <ul className="list-none mt-[10px] -ml-7">{children}</ul>
                ),
                li: ({ children }) => (
                  <li className="before:content-[''] before:h-[6px] before:w-[6px] before:rounded-full before:bg-black before:inline-block before:mr-4">
                    {children}
                  </li>
                ),
              }}
            />

            <div className="flex flex-col sm:space-x-7  sm:flex-row items-center mt-7">
              {data?.ctaButton1?.title && (
                <div className="w-[264px]">
                  <Button
                    btnText={data?.ctaButton1?.title}
                    btnLink={data?.ctaButton1?.link}
                    btnType={data?.ctaButton1?.variant}
                    linkType={data?.ctaButton1?.type}
                    formId={data?.ctaButton1?.formId}
                    downloadLink={data?.ctaButton1?.downloadLink}
                    fontSize="text-base"
                    spacing=""
                  />
                </div>
              )}
              {data?.ctaButton2?.title && (
                <div className="w-[264px]">
                  <Button
                    btnText={data?.ctaButton2?.title}
                    btnLink={data?.ctaButton2?.link}
                    btnType={data?.ctaButton2?.variant}
                    linkType={data?.ctaButton2?.type}
                    formId={data?.ctaButton2?.formId}
                    downloadLink={data?.ctaButton2?.downloadLink}
                    borderSide="right"
                    fontSize="text-base"
                    spacing=""
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
