import React, { useState } from "react";
import PortableText from "react-portable-text";
import leaf1 from "../../images/home/leaf1.webp";
import leaf2 from "../../images/home/leaf2.webp";
import leaf3 from "../../images/home/leaf3.webp";
import AccommodationSlider from "../accommodation/how_tranquilobay_different/AccommodationSlider";

export default function TranqiuloBay({ data }) {
  const [openSlider, setOpenSlider] = useState(false);
  return (
    <>
      <div
        style={{
          backgroundImage: `url(${leaf1}), url(${leaf3}), url(${leaf2})`,
          backgroundRepeat: "no-repeat, no-repeat, no-repeat",
          backgroundPosition: "left bottom, right bottom, 100% 35%",
          backgroundSize: "267px 305px, 307px 335px ,240px 350px",
        }}
      >
        <div className="max-w-[682px] mx-auto px-5 pt-[50px] pb-[70px] show">
          <p className=" text-[32px] font-primary leading-[46px] text-center">
            {data?.heading}
          </p>
          <div className="max-w-[615px] mt-[15px]">
            {data?._rawContent && (
              <PortableText
                content={data?._rawContent}
                className="text-base text-center font-poppins block-content max-w-none"
              />
            )}

            {data?.button && (
              <div className="max-w-[240px] mt-[40px] mx-auto">
                <button
                  onClick={() => setOpenSlider(true)}
                  className={`cursor-pointer px-[60px] leading-[38px] text-primary text-sm  h-[50px] sm:h-[70px] w-[240px] sm:w-[260px] border-primary hover:bg-primary hover:text-white tracking-[2px] font-semibold uppercase rounded-tl-[27px] rounded-br-[27px] text-center no-underline whitespace-nowrap bg-white  border border-solid box-border transition-all duration-200 ease-linear`}
                  aria-label={data?.button}
                >
                  {data?.button}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {data?.sliderItems && openSlider && (
        <AccommodationSlider
          onClose={() => setOpenSlider(false)}
          list={data?.sliderItems}
        />
      )}
    </>
  );
}
